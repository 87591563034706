import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { ContentList } from './user-login-add/content-list'
import { GroupList } from './user-login-add/group-list'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { IconAdd } from '../../../../../shared/icon/icon'
import { ButtonPrimary, ButtonSecondary } from '../../../../../shared/legacy/button'
import { UserList } from './user-login-add/user-list'
import { useWsContentAuth } from '../../../../../api/state'
import { UserImportCSV } from './user-login-add/user-import-csv'
import { Progress } from '../../../../../shared/legacy/progress'
import $ from 'jquery'

import { ROLE_ORG } from '../../../../../role/role.const'
import { RoleAccessDisplayOrg } from '../../../../../role/components/role-access-display'
import { getAccessToken } from '../../../../../storage/auth'
import { TileBody, TileColumn11111 } from '../../../../../shared/tile/tile-column-21111'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'

const ModalProgress = ({ show, progressEvent, onContinue }) => {
  useEffect(
    () => {
      show ? $('#modalProgressID').show() : $('#modalProgressID').hide()
    },
    [show]
  )

  const disableContinue = useMemo(
    () => {
      let hold = progressEvent === null || progressEvent.length === 0 || progressEvent.current !== progressEvent.length
      return hold
    },
    [progressEvent]
  )

  const handleContinue = () => {
    $('#modalProgressID').hide()

    onContinue()
  }

  var current = useMemo(() => (progressEvent ? progressEvent.current : 0), [progressEvent])
  var length = useMemo(() => (progressEvent ? progressEvent.length : 0), [progressEvent])

  return (
    <React.Fragment>
      <div className="modal" id="modalProgressID" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Add User Progress
              </h5>
            </div>
            <div className="modal-body">
              <Progress current={current} length={length} />
              {!disableContinue && <div className="pt-2">User Create Complete</div>}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary ${disableContinue && 'disabled'}`}
                disabled={disableContinue}
                onClick={handleContinue}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export function UserLoginAdd() {
  const history = useHistory()

  const [invites, updateInvites] = useState([{ email: '', firstName: '', lastName: '', roleID: null }])
  const [nodes, updateNodes] = useState([])
  const [groups, updateGroups] = useState([])
  const [valid, updateValid] = useState(false)

  const handleUserUpdate = invites => {
    if (!invites.length) {
      updateInvites([{ email: '', firstName: '', lastName: '', roleID: null }])
    } else {
      updateInvites(invites)
    }
  }

  const handleNodeUpdate = nodes => {
    updateNodes(nodes)
  }

  const handleGroupUpdate = groups => {
    updateGroups(groups)
  }

  const [progressEvent, updateProgressEvent] = useState(null)

  const onOpen = () => {}

  const onMessage = event => {
    if (event.action === 'progress') {
      updateProgressEvent(event.payload)
    }
  }

  const onClose = () => {}

  const onError = error => {}

  let accessToken = getAccessToken()

  let [wsAction, wsState, wsCleanup] = useWsContentAuth(`api/v1/register-invite/ws/create?auth=${accessToken}`)

  const handleInvite = async () => {
    let userInvites = invites.map(invite => {
      invite.nodeIDs = nodes.map(i => i.id)
      invite.groupIDs = groups.map(i => i.id)
      return invite
    })

    const [init, send] = wsAction(onOpen, onMessage, onClose, onError)

    await init()
    await send(userInvites)
  }

  const handleCancel = () => history.goBack()

  const handleSelectCSV = invites => {
    updateInvites(invites)
    wsCleanup()
    updateProgressEvent(null)
  }

  const handleError = hasError => updateValid(!hasError)

  const HeaderComponent = () => <UserImportCSV disabled={wsState.submitting} onSelect={handleSelectCSV} />

  const handleContinue = () => history.goBack()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAdd} text="add">
        <ModalProgress show={wsState.submitting || wsState.complete} progressEvent={progressEvent} onContinue={handleContinue} />
        <CardFullHeightScrollY title="Add Users" HeaderComponent={HeaderComponent}>
          <TileBody>
            <TileColumn11111>
              <UserList
                invites={invites}
                disabled={wsState.submitting || wsState.complete}
                onUpdate={handleUserUpdate}
                onError={handleError}
              />

              <RoleAccessDisplayOrg type={ROLE_ORG.NODE_USER} accessCreate>
                <ContentList nodes={nodes} disabled={wsState.submitting || wsState.complete} onUpdate={handleNodeUpdate} />
              </RoleAccessDisplayOrg>

              <RoleAccessDisplayOrg type={ROLE_ORG.NODE_GROUP} accessCreate>
                <GroupList groups={groups} disabled={wsState.submitting || wsState.complete} onUpdate={handleGroupUpdate} />
              </RoleAccessDisplayOrg>
            </TileColumn11111>

            <TileColumn11111>
              <ButtonSecondary text="back" onClick={handleCancel} />
              <ButtonPrimary text="invite" disabled={!valid || wsState.complete} onClick={handleInvite} />
            </TileColumn11111>
          </TileBody>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
